#main-container {
    max-width: 900px;
    margin-top: 10px;
}

#model-output {
    font-size: 1.5em;
    margin-bottom: 20px;
}

#model-output p {
    font-size: 0.6em;
}

#main-input {
    border: 1px solid rgba(0, 0, 0, .25);
    color: rgba(255, 255, 255, 0.8);
}

.preview-container {
    overflow-y: hidden;
    overflow-x: scroll;
}

.preview-container .row {
    width: 0;
    overflow: visible;
}

.preview-container .col {
    display: flex;
}

.image-preview {
    width: 60px;
    height: 60px;
    margin: 0 5px 3px 0;
    cursor: pointer;
    border-width: 2px;
    border-color: rgba(0, 0, 0, 0);
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    flex-shrink: 0
}

.image-preview img {
    object-fit: cover;
    flex-shrink: 0;
    min-width: 100%;
    min-height: 100%
}

.image-preview:hover {
    border: 2px solid rgba(238, 238, 238, 0.4);
}

.image-preview.current-selection {
    border: 2px solid rgba(238, 238, 238, 0.8);
    opacity: 1;
    cursor: auto;
}

#file-input {
    display: none;
}

.styled-scrollbars {
    --scrollbar-foreground: rgba(238, 238, 238, 0.4);
    --scrollbar-background: rgba(0, 0, 0, 0);
    /* Foreground, Background */
    scrollbar-color: var(--scrollbar-foreground) var(--scrollbar-background);
}

.styled-scrollbars::-webkit-scrollbar {
    height: 3px; /* Mostly for horizontal scrollbars */
}

.styled-scrollbars::-webkit-scrollbar-thumb { /* Foreground */
    background: var(--scrollbar-foreground);
}

.styled-scrollbars::-webkit-scrollbar-track { /* Background */
    background: var(--scrollbar-background);
    height: 3px;
}

em.sample-text {
    color: rgba(255, 255, 255, 0.6)
}